import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import ekycApi from '../services/ekycApi';

export const initialState = {
  loading: false,
  message: '',
  ekycRequest: {
    typeOfDocument: 'ID',
    loanAppId: '',
    fiCode: '',
    selfieImage: '',
    firstImage: '',
    secondImage: '',
  },

  ekycInformation: {
    loanAppId: '',
    name: '',
    mobileNo: '',
    nationalId: '',
    permanentAddress: '',
    temporaryAddress: '',
    birthday: '',
    gender: '',
    email: '',
    agreement: '',
  },
};

export const submitScanEkyc = createAsyncThunk(
  'ekyc/submitScan',
  async (data, { rejectWithValue }) => {
    try {
      const response = await ekycApi.ekycCreate(data);
      return response;
    } catch (err) {
      if (!err) {
        throw err;
      }
      return rejectWithValue(err);
    }
  },
);

export const submitEkycInfo = createAsyncThunk(
  'ekyc/submitInfo',
  async (data, { rejectWithValue }) => {
    try {
      const response = await ekycApi.ekycSubmit(data);
      return response;
    } catch (err) {
      if (!err) {
        throw err;
      }
      return rejectWithValue(err);
    }
  },
);

const ekycSlice = createSlice({
  name: 'ekyc',
  initialState,
  reducers: {
    resetEkycState: () => initialState,
    resetMessage(state) {
      state.message = null;
    },

    resetData(state) {
      state.message = null;
      state.ekycInformation.agreement = '';
    },

    setEkycImage(state, { payload }) {
      state.ekycRequest = payload;
    },

    setInfoEkycUser(state, { payload }) {
      state.ekycInformation = payload;
    },

    setMessage(state, { payload }) {
      state.message = payload;
    },

    setMessageCode(state, { payload }) {
      state.messageCode = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(submitScanEkyc.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(submitScanEkyc.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(submitScanEkyc.rejected, (state, { payload }) => {
      state.message = 'errorMessageContent3';
      state.loading = false;
    });
    builder.addCase(submitEkycInfo.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(submitEkycInfo.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(submitEkycInfo.rejected, (state, { payload }) => {
      state.message = payload.messageCode;
      state.loading = false;
    });
  },
});
export const {
  resetData,
  resetMessage,
  setEkycImage,
  setInfoEkycUser,
  setMessage,
  setMessageCode,
  resetEkycState,
} = ekycSlice.actions;
export default ekycSlice.reducer;
