export const DEFAULT_LANGUAGE = 'vi';

/**
 * Function ID for OTP request to specific screen/API call
 */
export const OTP_FUNCTION_ID = {
  CREATE_ACCOUNT: 1,
  E_SIGN: 2,
  CHANGE_PASSWORD: 3,
};

export const ESIGN_STATUS = {
  READY: 1,
  SIGNED: 2,
};

export const LOAN_STATUS = {
  10: {
    color: '#429777',
    name: 'quickLoanApplied',
  },
  20: {
    color: '#17b5bf',
    name: 'qualified',
  },
  21: {
    color: '#a9a9a9',
    name: 'disqualified',
  },
  30: {
    color: '#429777',
    name: 'qualifiedEKYCPass',
  },
  31: {
    color: '#ffb020',
    name: 'qualifiedEKYCFailed',
  },
  32: {
    color: '#6495ed',
    name: 'qualifiedInconsistentNID',
  },
  40: {
    color: '#429777',
    name: 'approved',
  },
  41: {
    color: '#696969',
    name: 'rejected',
  },
  50: {
    color: '#52bd94',
    name: 'signed',
  },
  60: {
    color: '#429777',
    name: 'disbursed',
  },
};

// @FIXME: replace with loan status const
export const LOAN_STATUS_STRING = {
  QUICK_LOAN_APPLIED: '10',
  QUALIFIED_PASS: '20',
  QUALIFIED_FAILED: '31',
};
