import axiosClient from './axiosClient';

export const loanApi = {
  loanApplicationList: async () => {
    const endPoint = '/loan-application/list';
    return await axiosClient.get(endPoint);
  },
  saveQuickLoan: async (data) => {
    const endPoint = '/loan-application/';
    return await axiosClient.post(endPoint, data);
  },
  getLoanCampaigns: async () => {
    const endPoint = '/loan-campaign/quick-loan/list';
    return await axiosClient.get(endPoint);
  },
  saveLoanCampaign: async (data) => {
    const endPoint = '/loan-application/campaign';
    return await axiosClient.post(endPoint, data);
  },
  latest: async () => {
    const endPoint = '/loan-application/latest';
    return await axiosClient.get(endPoint);
  },
  cancelCurrentLoanLatest: async (appId) => {
    const endPoint = `/loan-application/cancel-resume?appId=${appId}`;
    return await axiosClient.put(endPoint);
  },
  loanPreCheck: async (data) => {
    const endPoint = '/loan-application/pre-check';
    return await axiosClient.post(endPoint, data);
  },
  getLoanCampaign: async (accessCode) => {
    const endPoint = `/loan-campaign?urlAccessKey=${accessCode}`;
    return await axiosClient.get(endPoint);
  },
};
