import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { qualifyApi } from '../services/qualifyApi';

export const initialState = {
  loading: false,
  message: null,
  application: {
    applicationID: '',
    campaignID: '',
    fiName: '',
  },
  qualifyResult: null,
  setQualifyData: null, // @TODO: remove it
  qualifyData: null, // request data // qualify result
  applicationID: null,
  resumeResponse: {},
};

export const saveInfo = createAsyncThunk(
  'qualify/saveInfo',
  async (data, { rejectWithValue }) => {
    try {
      const res = await qualifyApi.saveInfo(data);
      return res;
    } catch (err) {
      if (!err) {
        throw err;
      }
      return rejectWithValue(err);
    }
  },
);

const qualifySlice = createSlice({
  name: 'qualify',
  initialState,
  reducers: {
    setQualifyData: (state, { payload }) => {
      state.qualifyData = { ...payload };
    },
    setQualifyResult: (state, { payload }) => {
      state.qualifyResult = payload;
    },
    setLoanApplication: (state, { payload }) => {
      state.application = payload;
    },
    setResumeResponse: (state, { payload }) => {
      state.resumeResponse = { ...payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(saveInfo.rejected, (state, { payload }) => {
      state.message = payload.messageCode;
    });
  },
});

export const {
  setQualifyData,
  setQualifyResult,
  setApplicationID,
  setResumeResponse,
  setLoanApplication,
} = qualifySlice.actions;

export default qualifySlice.reducer;
