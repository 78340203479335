import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { userApi } from '../services/userApi';

export const initialState = {
  loading: false,
  message: null,
  infoCreateAccount: {},
  userInfo: {
    name: '',
    mobileNo: '',
    nationalId: '',
    originLocation: '',
    recentLocation: '',
    birthday: '',
    gender: '',
    email: '',
    agreement: '',
  },
  currentOTP: {},
};

export const createAccount = createAsyncThunk(
  'user/createAccount',
  async (data, { rejectWithValue }) => {
    try {
      const res = await userApi.createAccount(data);
      return res;
    } catch (err) {
      if (!err) {
        throw err;
      }
      return rejectWithValue(err);
    }
  },
);

export const getOTPCode = createAsyncThunk(
  'user/getOTPCode',
  async (data, { rejectWithValue }) => {
    try {
      const res = await userApi.getOTP(data);
      return res;
    } catch (err) {
      if (!err) {
        throw err;
      }
      return rejectWithValue(err);
    }
  },
);

export const verifyOTPCode = createAsyncThunk(
  'user/verifyOTPCode',
  async (data, { rejectWithValue }) => {
    try {
      const res = await userApi.verifyOTP(data);
      return res;
    } catch (err) {
      if (!err) {
        throw err;
      }
      return rejectWithValue(err);
    }
  },
);

export const changePassword = createAsyncThunk(
  'user/change-password',
  async (data, { rejectWithValue }) => {
    try {
      const res = await userApi.changePassword(data);
      return res;
    } catch (err) {
      if (!err) {
        throw err;
      }
      return rejectWithValue(err);
    }
  },
);

export const isExisted = createAsyncThunk(
  'user/is-existed',
  async (username, { rejectWithValue }) => {
    try {
      const res = await userApi.isExisted(username);
      return res;
    } catch (err) {
      if (!err) {
        throw err;
      }
      return rejectWithValue(err);
    }
  },
);

export const userProfile = createAsyncThunk(
  'user/userProfile',
  async (data, { rejectWithValue }) => {
    try {
      const res = await userApi.getUserProfile();
      return res;
    } catch (err) {
      if (!err) {
        throw err;
      }
      return rejectWithValue(err);
    }
  },
);

export const submitUserInfo = createAsyncThunk(
  'ekyc/submitUserInfo',
  async (data, { rejectWithValue }) => {
    try {
      const response = await userApi.userInfoSubmit(data);
      return response;
    } catch (err) {
      if (!err) {
        throw err;
      }
      return rejectWithValue(err);
    }
  },
);

export const uploadAvatar = createAsyncThunk(
  'ekyc/uploadAvatar',
  async (data, { rejectWithValue }) => {
    try {
      const response = await userApi.uploadAvatar(data);
      return response;
    } catch (err) {
      if (!err) {
        throw err;
      }
      return rejectWithValue(err);
    }
  },
);

export const preCheckPhone = createAsyncThunk(
  'user/preCheckPhone',
  async (data, { rejectWithValue }) => {
    try {
      const res = await userApi.preCheckPhone(data);
      return res;
    } catch (err) {
      if (!err) {
        throw err;
      }
      return rejectWithValue(err);
    }
  },
);

export const preCheckNid = createAsyncThunk(
  'user/preCheckNid',
  async (data, { rejectWithValue }) => {
    try {
      const res = await userApi.preCheckNid(data);
      return res;
    } catch (err) {
      if (!err) {
        throw err;
      }
      return rejectWithValue(err);
    }
  },
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setInfoCreateAccount(state, { payload }) {
      state.infoCreateAccount = payload;
    },
    setUserInfo(state, { payload }) {
      state.userInfo = payload;
    },
    setMessage(state, { payload }) {
      state.message = payload;
    },
    setInfoUser(state, { payload }) {
      state.userInfo = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createAccount.fulfilled, (state, { payload }) => {
      state.message = null;
    });
    builder.addCase(createAccount.rejected, (state, { payload }) => {
      state.message = payload.messageCode;
    });
    builder.addCase(getOTPCode.fulfilled, (state, { payload }) => {
      state.message = null;
      state.currentOTP = payload.data;
    });
    builder.addCase(getOTPCode.rejected, (state, { payload }) => {
      state.message = payload.messageCode;
    });
    builder.addCase(verifyOTPCode.fulfilled, (state, { payload }) => {
      state.message = null;
    });
    builder.addCase(verifyOTPCode.rejected, (state, { payload }) => {
      state.message = payload.messageCode;
    });
    builder.addCase(changePassword.fulfilled, (state, { payload }) => {});
    builder.addCase(changePassword.rejected, (state, { payload }) => {
      state.message = payload.messageCode;
    });
    builder.addCase(isExisted.fulfilled, (state, { payload }) => {});
    builder.addCase(isExisted.rejected, (state, { payload }) => {
      state.message = payload.messageCode;
    });
    builder.addCase(userProfile.rejected, (state, { payload }) => {
      state.message = payload.messageCode;
    });
    builder.addCase(userProfile.fulfilled, (state, { payload }) => {
      state.userInfo = payload.data;
      state.message = null;
    });
    builder.addCase(submitUserInfo.rejected, (state, { payload }) => {
      state.message = payload.messageCode;
    });
    builder.addCase(submitUserInfo.fulfilled, (state, { payload }) => {
      state.message = null;
    });

    builder.addCase(uploadAvatar.rejected, (state, { payload }) => {
      state.message = payload.messageCode;
    });
    builder.addCase(uploadAvatar.fulfilled, (state, { payload }) => {
      state.message = null;
    });
  },
});

export const { setInfoCreateAccount, setMessage, setUserInfo, setInfoUser } =
  userSlice.actions;

export default userSlice.reducer;
