import { LOAN_STATUS } from '../../../constants/common';
import { device } from '../../../styles/device';
import styled from 'styled-components';

export const ESignContainer = styled.div`
  background: var(--color-neutral-n200);
  @media ${device.desktop} {
    background: var(--color-neutral-n0);
  }
`;

export const QuickloanHeader = styled.div`
  background: #ffffff;
  border: 1.5px solid rgba(212, 228, 240, 0.6);
  box-shadow: 0px 10px 0px rgba(212, 228, 240, 0.8);
  border-radius: 20px;
  max-width: 760px;
  margin: 53px auto 30px;
  .bg-header {
    height: 170px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 20px;
    padding: 0 28px;
    position: relative;
  }
`;

export const ESignHeader = styled.div`
  padding: 16px 16px 0;

  .bg-header {
    min-height: 114px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 20px;
    background: linear-gradient(223.99deg, #368ee1 19.73%, #2f78be 81.9%);
    padding: 0 28px;
    position: relative;
    overflow: hidden;
  }
`;

export const ESignColabButton = styled.div`
  background: var(--color-neutral-n400);
  border-radius: 2px;
  width: 38px;
  height: 5px;
  margin: 12px auto;
  @media ${device.desktop} {
    background: var(--color-neutral-n200);
  }
`;

export const ESignHeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  color: var(--color-neutral-n0);
  position: relative;

  &::before {
    content: '';
    border-radius: 50%;
    position: absolute;
    height: 4px;
    width: 4px;
    background-color: #9cb9fc;
    top: 10px;
    left: -14px;
  }

  .say-hi {
    font-weight: 400;
    font-size: 14px;
    position: relative;
    &::before,
    &::after {
      content: '';
      border-radius: 50%;
      position: absolute;
    }
    &::before {
      height: 6px;
      width: 6px;
      background-color: #e3e4b2;
      top: -11px;
      left: -5px;
    }
    &::after {
      height: 5px;
      width: 5px;
      background-color: #b1b4f1;
      top: -6px;
      left: 19px;
    }
  }
  .say-welcome {
    font-weight: 590;
    font-size: 18px;
    line-height: 130%;
  }
  .btn-get-a-loan {
    padding-left: 20px;
    padding-right: 20px;
    width: 200px;
    color: #fff;
  }
`;

export const QuickloanHeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  color: var(--color-neutral-n0);
  position: relative;

  .quickloan-label {
    font-weight: 600;
    font-size: 18px;
    color: var(--color-neutral-n900);
  }
  .quickloan-content {
    font-weight: 400;
    font-size: 14px;
    color: var(--color-neutral-n700);
    margin-bottom: 20px;
  }
  .btn-get-a-loan {
    padding-left: 20px;
    padding-right: 20px;
    width: 200px;
    color: #fff;
  }
`;

export const ESignHeaderAvatar = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 1px solid var(--color-neutral-n0);
  overflow: hidden;
  flex: 0 0 48px;
  position: relative;
  z-index: 1;
  margin-left: 16px;
  .inner-avatar {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .inner-picture {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  @media ${device.desktop} {
    width: 210px;
    height: 143px;
    border-radius: 0;
    position: absolute;
    right: 20px;
    bottom: 0;
  }
`;

export const SwiperSlideItem = styled.div`
  background: #ffffff;
  border: 1.5px solid rgba(212, 228, 240, 0.6);
  box-shadow: 0px 10px 0px rgba(212, 228, 240, 0.8);
  border-radius: 20px;
  width: 100%;
  @media ${device.desktop} {
    margin: 0 auto 30px;
    max-width: 760px;
  }
`;

export const SwiperSlideItemHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px;
  border-bottom: 1px solid var(--color-neutral-n300);
  .header-text-left {
    margin-right: 8px;
    .loan-name-id {
      color: var(--color-neutral-n900);
      font-weight: 590;
      font-size: 16px;
    }
  }
  .header-block-right {
    display: flex;
    align-items: center;
    gap: 10px;
    .bank-logo {
      max-height: 18px;
      height: 100%;
      object-fit: contain;
    }
    .bank-name {
    }
  }
`;

export const SwiperSlideItemBody = styled.div`
  padding: 8px 18px 18px 18px;
  .wrapper-text {
    display: flex;
    border-bottom: 1px solid var(--color-neutral-n100);
    padding: 8px 0;
    &:last-child {
      border-bottom: none;
    }
    @media ${device.desktop} {
      flex-direction: column;
      padding: 10px;
      :nth-child(n+5) {
        border-bottom: none;
      }
    }
  }
  @media ${device.desktop} {
    display: grid;
    grid-template-columns: repeat(4, 25%);
  }
`;

export const SwiperSlideItemBodyTitle = styled.span`
  color: var(--color-neutral-n900);
  font-weight: 510;
  font-size: 14px;
  flex: 1;
`;

export const SwiperSlideItemBodyText = styled.span`
  color: var(--color-neutral-n800); //default color
  font-weight: 510;
  font-size: 14px;
  flex: 1;
  ${(props) => props.type === 'link' && 'color: #108FEB;'}
  ${(props) => `color: ${LOAN_STATUS[props.status]?.color}`}
`;

export const ESignFooter = styled.div`
  background: var(--color-neutral-n0);
  height: 50px;
  clip-path: polygon(50% 65%, 61% 70%, 100% 100%, 0 100%, 39% 70%);
`;

export const ESignFooterContainer = styled.div`
  background: var(--color-neutral-n0);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 16px 48px;
  text-align: center;

  .eSign-footer-apply {
    font-weight: 590;
    font-size: 18px;
    color: var(--color-neutral-n900);
    margin-bottom: 6px;
    margin-top: 17px;
  }
  .eSign-footer-now {
    font-weight: 400;
    font-size: 14px;
    color: var(--color-neutral-n700);
    margin-bottom: 16px;
    line-height: 130%;
  }
  .btn-get-a-loan {
    padding-left: 20px;
    padding-right: 20px;
    width: max-content;
    color: #fff;
  }
`;

export const ESignColabButtonFooter = styled.div`
  background: var(--color-neutral-n400);
  border-radius: 2px;
  width: 38px;
  height: 5px;
  margin: 0px auto;
`;

export const ESignEmptyLoan = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 25px;
  .ESign-empty-loan-text {
    font-weight: 400;
    font-size: 16px;
    color: var(--color-neutral-n600);
  }
`;

export const CustomModal = styled.div`
  display: flex;
  flex-direction: column;
  gap: 11px;
  .title {
    font-weight: 590;
    font-size: 18px;
    color: var(--color-neutral-n900);
  }
  .description {
    font-weight: 400;
    font-size: 14px;
    color: var(--color-neutral-n700);
    white-space: pre-line;
  }

  .wrapper-button {
    display: flex;
    gap: 10px;
  }
`;

export const ButtonModal = styled.button`
  flex: 1;
  ${(props) =>
    props.yes &&
    'background:var(--color-neutral-n0) ; color:var(--color-neutral-n700) ; border:1px solid var(--color-neutral-n400)'}
`;
