import React from 'react';
import routeConstant from '../../constants/routes';

// Hompage
const HomePage = React.lazy(() => import('../views/Home'));
// Authen/register
const LoginPage = React.lazy(() => import('../views/LoginPage'));
const CreateAccountPage = React.lazy(() => import('../views/CreateAccount'));
const ForgotPassword = React.lazy(() => import('../views/ForgotPassword'));
const ForgotPasswordOTP = React.lazy(() =>
  import('../views/ForgotPassword/otp'),
);
const ChangePassword = React.lazy(() => import('../views/ChangePassword'));
const CreateAccountOTP = React.lazy(() => import('../views/VerifyOtp'));
// EKYC
const EKYCStartedPage = React.lazy(() => import('../views/Ekyc/StartedPage'));
const EKYCStep1 = React.lazy(() => import('../views/Ekyc/Steps/FirstStep'));
const EKYCStep2 = React.lazy(() => import('../views/Ekyc/Steps/SecondStep'));
const EKYCStep3 = React.lazy(() => import('../views/Ekyc/Steps/ThirdStep'));
// Qualify

// ESIGN
const EsignPdf = React.lazy(() => import('../views/E-Sign/pdfViewer'));
// QUICK LOAN
const QuickLoanApplication = React.lazy(() =>
  import('../views/QuickLoan/Application/index'),
);
const QuickLoanPlan = React.lazy(() =>
  import('../views/QuickLoan/Application/loanPlan'),
);
// PROFILE
const ProfilePage = React.lazy(() => import('../views/UserProfile'));
// FAQ & Privacy
const FAQPage = React.lazy(() => import('../views/FAQ'));

const SendQuestion = React.lazy(() => import('../views/FAQ/sendQuestion'));

const routes = [
  {
    path: routeConstant.HOME,
    isPrivate: true,
    exact: true,
    component: HomePage,
    isDashboard: true,
  },
  {
    path: routeConstant.LOGIN,
    isPrivate: false,
    component: LoginPage,
    exact: true,
    isDashboard: false,
  },
  {
    path: routeConstant.VERIFY_OTP,
    isPrivate: false,
    component: CreateAccountOTP,
    exact: false,
    isDashboard: false,
  },
  {
    path: routeConstant.FORGOT_PASSWORD_VERIFY_OTP,
    isPrivate: false,
    component: ForgotPasswordOTP,
    exact: false,
    isDashboard: false,
  },
  {
    path: [routeConstant.CREATE_ACCOUNT, routeConstant.CREATE_ACCOUNT_SMS],
    isPrivate: false,
    component: CreateAccountPage,
    exact: true,
    isDashboard: false,
  },
  {
    path: routeConstant.FORGOT_PASSWORD,
    isPrivate: false,
    component: ForgotPassword,
    isDashboard: false,
  },
  {
    path: routeConstant.CHANGE_PASSWORD,
    isPrivate: false,
    component: ChangePassword,
    isDashboard: false,
  },
  {
    path: routeConstant.EKYC_START,
    isPrivate: true,
    component: EKYCStartedPage,
    isDashboard: false,
  },
  {
    path: routeConstant.EKYC_STEP1,
    isPrivate: true,
    component: EKYCStep1,
    isDashboard: false,
  },
  {
    path: routeConstant.EKYC_STEP2,
    isPrivate: true,
    component: EKYCStep2,
    isDashboard: false,
  },
  {
    path: routeConstant.EKYC_STEP3,
    isPrivate: true,
    component: EKYCStep3,
    isDashboard: false,
  },
  {
    path: routeConstant.FAQ_QUESTION,
    isPrivate: false,
    component: SendQuestion,
    exact: false,
    isDashboard: false,
  },
  {
    path: routeConstant.FAQ,
    isPrivate: false,
    component: FAQPage,
    isDashboard: false,
  },
  {
    path: routeConstant.ESIGN_PDF,
    isPrivate: true,
    component: EsignPdf,
    exact: true,
    isDashboard: false,
  },
  {
    path: routeConstant.QUICK_LOAN,
    isPrivate: false,
    exact: true,
    component: QuickLoanApplication,
    isDashboard: false,
  },
  {
    path: routeConstant.QUICK_LOAN_PLAN,
    isPrivate: false,
    exact: true,
    component: QuickLoanPlan,
    isDashboard: false,
  },
  {
    path: routeConstant.USER_PROFILE,
    isPrivate: true,
    exact: true,
    component: ProfilePage,
    isDashboard: true,
  },
];

export default routes;
