import { AvatarContainer, HomeAvatar } from './style';

import { BASE64FORMAT } from '../../../constants/constants';
import { Link } from 'react-router-dom';
import React from 'react';
import { SVG } from '../../../asset/img';
import { isEmpty } from 'lodash';
import routeConstant from '../../../constants/routes';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const Welcome = () => {
  const { t } = useTranslation();
  const { loginInfo } = useSelector((state) => state?.auth);
  const { userInfo } = loginInfo;
  const avatar = !isEmpty(loginInfo.userInfo?.avatar)
    ? BASE64FORMAT + loginInfo.userInfo?.avatar
    : SVG.User;

  return (
    <AvatarContainer>
      <div className='say-hi'>
        <HomeAvatar>
          <Link to={routeConstant.USER_PROFILE}>
            <img className='inner-avatar' alt='avt' src={avatar} />
          </Link>
        </HomeAvatar>
      </div>
      <div className='say-welcome'>
        <span className='goodmorning'>{t('esign.goodMorning')}</span>
        <div className='fullName'>
          {userInfo && userInfo.name && userInfo.name}
        </div>
      </div>
    </AvatarContainer>
  );
};

export default Welcome;
