import './app.css';

import React, { Suspense } from 'react';
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';

import PrivateRoute from '../../components/private-route/index';
import ScrollToTop from '../../components/ScrollToTop';
import Spinner from '../../components/spinner';
import i18n from 'i18next';
import routes from './routes';
import { useSelector } from 'react-redux';

function App() {
  const { lang, loading } = useSelector((state) => state.auth);

  React.useEffect(() => {
    // setup lan by user info + change language
    i18n.changeLanguage(lang);
  }, [lang]);

  const renderRoute = () =>
    routes.map((route) =>
      route.isPrivate ? (
        <PrivateRoute
          key={route.path}
          path={route.path}
          component={route.component}
          exact={route.exact}
          isDashboard={route.isDashboard}
        />
      ) : (
        <Route
          key={route.path}
          path={route.path}
          component={route.component}
          exact={route.exact}
        />
      ),
    );

  // Set timezone if any necessary
  // React.useEffect(() => {
  // moment.tz.setDefault('Asia/Dubai');
  // }, []);

  return (
    <div className='App'>
      {loading && <Spinner />}
      <Router>
        <Suspense fallback={''}>
          <ScrollToTop />
          <Switch>
            {renderRoute()}
            <Redirect to='/login' />
          </Switch>
        </Suspense>
      </Router>
    </div>
  );
}

export default App;
