import styled from 'styled-components';

export const SpinnerWrap = styled.div({
  '.spinnerOverlay': {
    flexDirection: 'column',
    position: 'fixed',
    zIndex: 1001,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    backdropFilter: 'blur(3px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '.loading-text': {
      fontSize: 14,
      fontWeight: 400,
      color: 'var(--color-neutral-n800)',
      marginTop: '-3rem',
    },
  },
});
