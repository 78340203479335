import { Button } from 'antd';
import styled from 'styled-components';
import { ESignHeaderContent } from '../../views/E-Sign/style';

export const AvatarContainer = styled(ESignHeaderContent)`
  display: flex;
  flex-direction: column;
  color: var(--color-neutral-n0);
  position: relative;
  margin: 35px 0 0 20px;
  &::before {
    top: 16px;
  }
  .say-hi {
    &::before {
      top: -7px;
      left: -4px;
    }
    &::after {
      left: 24px;
    }
  }
  .say-welcome {
    margin-top: 16px;
    .goodmorning {
      font-weight: 400;
      font-size: 14px;
      opacity: 0.78;
    }
    .fullName {
      font-weight: 600;
      font-size: 20px;
      line-height: 26px;
    }
  }
`;

export const HomeAvatar = styled.div`
  width: 54px;
  height: 54px;
  border-radius: 50%;
  border: 1px solid var(--color-neutral-n0);
  overflow: hidden;
  flex: 0 0 48px;
  position: relative;
  z-index: 1;
  margin-top: 8px;
  .inner-avatar {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const MenuBarContainer = styled.div`
  height: 650px;
`;
export const LogoutButton = styled(Button)`
  color: var(--color-neutral-n0);
  img {
    padding: 0 12px 3px 0;
  }

  &:hover {
    color: var(--color-neutral-n0);
    opacity: 0.7;
  }
`;
