import { logout } from '../../../redux/authSlice';

import { Layout } from 'antd';
import { LogoutButton } from './style';
import MenuBar from './menuBar';
import React from 'react';
import { SVG } from '../../../asset/img';
import Welcome from './welcome';
import routes from '../../../constants/routes';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const { Sider, Content, Footer } = Layout;

const DashBoard = ({ children }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const onLogout = () => {
    dispatch(logout());
    history.push(routes.LOGIN);
  };

  return (
    <Layout
      style={{
        minHeight: '100vh',
        overflow: 'hidden',
      }}
      hasSider
    >
      <Sider
        className='nice-sider'
        style={{
          overflow: 'hidden',
          height: '100vh',
          position: 'fixed',
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <Welcome />
        <MenuBar />
        <Footer
          style={{
            background: 'transparent',
            color: 'var(--color-neutral-n0)',
            position: 'fixed',
            bottom: 0,
            padding: '24px 50px 24px 0',
          }}
        >
          <LogoutButton
            type='link'
            icon={<img src={SVG.Logout} alt='' />}
            onClick={onLogout}
          >
            {t('label.signOut')}
          </LogoutButton>
        </Footer>
      </Sider>
      <Layout
        className='site-layout'
        style={{
          marginLeft: 280,
          background: '#fff',
        }}
      >
        <Content style={{ padding: 16, marginBottom: 48 }}>
          <div
            style={{
              minHeight: 360,
              position: 'relative',
            }}
          >
            {children}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default DashBoard;
