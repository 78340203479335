import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { esignApi } from '../services/esignApi';

export const initialState = {
  loading: false,
  message: null,
  sendContractState: null,
  signContractState: null,
  currentOTP: null,
  currentApplicationId: null,
  currentContractFile: null,
  currentContractStatus: null,
};

export const sendContract = createAsyncThunk(
  'esign/send-contract',
  async (data, { rejectWithValue }) => {
    try {
      const res = await esignApi.sendContract(data);
      return res;
    } catch (err) {
      if (!err) {
        throw err;
      }
      return rejectWithValue(err);
    }
  },
);

export const signContract = createAsyncThunk(
  'esign/sign-contract',
  async (data, { rejectWithValue }) => {
    try {
      const res = await esignApi.signContract(data);
      return res;
    } catch (err) {
      if (!err) {
        throw err;
      }
      return rejectWithValue(err);
    }
  },
);

export const resendOTP = createAsyncThunk(
  'esign/resend-otp',
  async (data, { rejectWithValue }) => {
    try {
      const res = await esignApi.resendOtp(data);
      return res;
    } catch (err) {
      if (!err) {
        throw err;
      }
      return rejectWithValue(err);
    }
  },
);

const esignSlice = createSlice({
  name: 'esign',
  initialState,
  reducers: {
    setSendEsignContract(state, { payload }) {
      state.sendContractState = payload?.data;
    },
    setMessage(state, { payload }) {
      state.message = payload;
    },
    setCurrentApplicationId(state, { payload }) {
      state.currentApplicationId = payload;
    },
    setCurrentContractFile(state, { payload }) {
      state.currentContractFile = payload;
    },
    setCurrentContractStatus(state, { payload }) {
      state.currentContractStatus = payload;
    },
    setSignContract(state, { payload }) {
      state.signContractState = payload?.data;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(sendContract.fulfilled, (state, { payload }) => {
      state.message = null;
    });
    builder.addCase(sendContract.rejected, (state, { payload }) => {
      state.message = payload.messageCode;
    });

    builder.addCase(signContract.fulfilled, (state, { payload }) => {
      state.message = null;
    });
    builder.addCase(signContract.rejected, (state, { payload }) => {
      state.message = payload.messageCode;
    });

    builder.addCase(resendOTP.fulfilled, (state, { payload }) => {
      state.message = null;
    });
    builder.addCase(resendOTP.rejected, (state, { payload }) => {
      state.message = payload.messageCode;
    });
  },
});

export const {
  setSendEsignContract,
  setMessage,
  setSignContract,
  setCurrentApplicationId,
  setCurrentContractFile,
  setCurrentContractStatus,
  setOtpId,
} = esignSlice.actions;

export default esignSlice.reducer;
