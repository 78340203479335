import Lottie from 'react-lottie';
import React from 'react';
import { SpinnerWrap } from './style';
import loading from '../../asset/animate/loading.json';
import { useTranslation } from 'react-i18next';

const defaultAnimateOptions = {
  loop: true,
  autoplay: true,
  animationData: loading,
  renderer: 'svg',
};

function Spinner() {
  const { t } = useTranslation();

  return (
    <SpinnerWrap>
      <div className='spinnerOverlay'>
        <Lottie
          options={defaultAnimateOptions}
          width='10rem'
          height='auto'
          className='loading-effect'
        />

        <p className='loading-text'>{t('loadingText')}</p>
      </div>
    </SpinnerWrap>
  );
}

export default Spinner;
