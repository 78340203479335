import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RESET_APP } from './actionTypes';
import { authApi } from '../services/authApi';

export const initialState = {
  logo: null,
  lang: 'vi',
  user: null,
  loading: false,
  message: null,
  typeOfFianceLoan: 'nice',
  isLeadGenCustomer: false,
  fiInfo: {},
  loginInfo: {
    isLogin: false,
  },
  accessToken: null,
};

export const loginTo = createAsyncThunk(
  'auth/login',
  async (data, { rejectWithValue }) => {
    try {
      const res = await authApi.login(data);
      return res;
    } catch (err) {
      if (!err) {
        throw err;
      }
      return rejectWithValue(err);
    }
  },
);

export const logout = createAsyncThunk(
  'auth/logout',
  async (_, { rejectWithValue }) => {
    try {
      const res = await authApi.logout();
      return res;
    } catch (err) {
      if (!err) {
        throw err;
      }
      return rejectWithValue(err);
    }
  },
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetFiInfo(state) {
      state.fiInfo = {};
      state.logo = '';
      state.isLeadGenCustomer = false;
    },
    setLeadGenCustomer(state, { payload }) {
      state.isLeadGenCustomer = payload;
    },
    setFiInfo(state, { payload }) {
      state.fiInfo = payload;
    },
    setLoading(state, { payload }) {
      state.loading = payload;
    },
    changeLanguage(state, action) {
      state.lang = action.payload;
    },
    // @TODO: confirm this reducer need rm or not
    setAccessToken(state, { payload }) {
      state.accessToken = payload;
    },
    setCustomer(state, { payload }) {
      state.isLeadGenCustomer = payload;
    },
    setMessage(state, { payload }) {
      state.message = payload;
    },
    setInfoAuth(state, { payload }) {
      state.loginInfo.userInfo = payload;
    },
    setLogo(state, { payload }) {
      state.logo = payload.fiLogo;
    },
    resetIsLoginState: (state) => {
      return { ...initialState, lang: state.lang };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginTo.fulfilled, (state, { payload }) => {
      state.loginInfo = { ...payload.data, isLogin: true };
      state.message = null;
    });
    builder.addCase(loginTo.rejected, (state, { payload }) => {
      state.message = payload.messageCode;
    });
    builder.addCase(logout.fulfilled, (state, action) => {
      Object.assign(state, initialState);
      action.type = RESET_APP;
    });
    builder.addCase(logout.rejected, (state, action) => {
      Object.assign(state, initialState);
      action.type = RESET_APP;
    });
  },
});

export const {
  changeLanguage,
  resetFiInfo,
  setAuth,
  setFiInfo,
  setInfoAuth,
  setLeadGenCustomer,
  setLoading,
  setMessage,
  setLogo,
  resetIsLoginState,
} = authSlice.actions;

export default authSlice.reducer;
