/**
 * How to use this file?!
 *
 * In your styled file or components just easy to import then re-use them.
 * For examples:
 * --------
 *
 * import { device } from './device';
 *
 * const Page = styled.div`
 *  margin: auto;
 *
 *  @media ${device.desktop} {
 *    max-width: 800px;
 *  }`;
 *
 * -------
 */

// Base on Antd breakpoint size
const size = {
  xs: '480px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1600px',
};

export const device = {
  // Small devices (old) (480px and up)
  mobileS: `(min-width: ${size.xs})`,
  // Mobile devices (576px and up)
  mobileL: `(min-width: ${size.sm})`,
  // Medium devices (tablets, 768px and up)
  tablet: `(min-width: ${size.md})`,
  // Large devices (desktops, 992px and up)
  desktop: `(min-width: ${size.lg})`,
  // Extra large devices (large desktops, 1200px and up)
  desktopL: `(min-width: ${size.xl})`,
  // XX Large devices (large desktops, 1600px and up)
  largeScreen: `(min-width: ${size.xxl})`,

  // Mobile only
  mobileOnly: `(max-width: ${size.sm})`,
};
