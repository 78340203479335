/* eslint-disable */
export const SESSION_EXPIRE_TIME = 30 * 60 * 1000;
export const DEFAULT_PAGESIZE = 10;

export const PASSWORD_MINLENGTH = 8;
export const PASSWORD_MAXLENGTH = 20;
export const PHONE_MAXLENGTH = 10;
export const NID_MAXLENGTH = 12;
export const NID_MINLENGTH = 9;
export const REGEX_DIGITS_ONLY = /^[0-9]*$/;
export const REGEX_NID = /^(?:[0-9]{9}|[0-9]{12})$/;
export const REGEX_EMAIL =
  /^(?=.{1,64}@)[a-zA-Z0-9]([-_.]?[a-zA-Z0-9])*@(?=.{1,253}$)[a-zA-Z0-9]([-]?[a-zA-Z0-9])*(\.[a-zA-Z0-9]{2,})+$/;
export const REGEX_PASSWORD =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;

export const CHECK_BASE64_REGEX =
  /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/;

export const BASE64FORMAT = 'data:image/jpeg;base64,';
export const LOCALE_VN = 'vi-VN';
export const MAX_LENGHT = 255;
export const TIME_DEBOUNCE = 1000;
export const MAXIMUM_FILE = 1024 * 1024 * 5;
