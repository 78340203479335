import EN_APIErrors from './locales/en/apiErrors.json';
import EN_AUTHEN from './locales/en/authen.json';
import EN_Activity from './locales/en/activity.json';
import EN_EkycScan from './locales/en/ekycScan.json';
import EN_FAQ from './locales/en/faq.json';
import EN_LoanStatus from './locales/en/loanStatus.json'
import EN_ValidationRules from './locales/en/validationRules.json';
import LanguageDetector from 'i18next-browser-languagedetector';
import VI_ValidationRules from './locales/vi/validationRules.json';
import VN_APIErrors from './locales/vi/apiErrors.json';
import VN_AUTHEN from './locales/vi/authen.json';
import VN_Activity from './locales/vi/activity.json';
import VN_EkycScan from './locales/vi/ekycScan.json';
import VN_FAQ from './locales/vi/faq.json';
import VN_LoanStatus from './locales/vi/loanStatus.json';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  vi: {
    translation: {
      ...VN_Activity,
      ...VN_AUTHEN,
      ...VN_APIErrors,
      ...VI_ValidationRules,
      ...VN_EkycScan,
      ...VN_FAQ,
      ...VN_LoanStatus
    },
  },
  en: {
    translation: {
      ...EN_Activity,
      ...EN_AUTHEN,
      ...EN_APIErrors,
      ...EN_ValidationRules,
      ...EN_EkycScan,
      ...EN_FAQ,
      ...EN_LoanStatus
    },
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'vi',
    debug: false,
    resources,
    interpolation: {
      escapeValue: false,
      format: function (value, format, lng) {
        if (format === 'lowercase') return value.toLowerCase();
        if (format === 'capitalize') {
          return `${value.substr(0, 1).toUpperCase()}${value.substr(1)}`;
        }
        return value;
      },
    },
  });

export default i18n;
