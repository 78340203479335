import axiosClient from './axiosClient';

export const esignApi = {
  sendContract: async (data) => {
    const endPoint = '/esign/send-contract';
    return await axiosClient.post(endPoint, data);
  },
  signContract: async (data) => {
    const endPoint = '/esign/sign-contract';
    return await axiosClient.post(endPoint, data);
  },
  resendOtp: async (data) => {
    const endPoint = '/esign/resend-otp';
    return await axiosClient.post(endPoint, data);
  },
};
