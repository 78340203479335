import { useEffect, useState } from 'react';

/**
 *
 * @param {number} size
 * @returns boolean is current screen width > size
 */
export const useWindowSide = (size) => {
  const [width, setWidth] = useState(0);
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [setWidth]);
  return width > size;
};
