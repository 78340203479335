import axiosClient from './axiosClient';

const ekycApi = {
  ekycCreate: (data) => {
    const endPoint = '/ekyc/verify';
    return axiosClient.post(endPoint, data);
  },
  ekycSubmit: (data) => {
    const endPoint = '/ekyc/update';
    return axiosClient.post(endPoint, data);
  },
};

export default ekycApi;
