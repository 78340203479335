import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { loanApi } from '../services/loanApi';

export const initialState = {
  loading: false,
  message: '',
  loanInfo: '',
  quickLoanFormData: undefined,
  tempDataForEKYC: {
    quickLoanEmail: '',
    quickLoanAddress: '',
  },
};

export const getLoanApplicationList = createAsyncThunk(
  'loan-application/list',
  async (data, { rejectWithValue }) => {
    try {
      const res = await loanApi.loanApplicationList(data);
      return res;
    } catch (err) {
      if (!err) {
        throw err;
      }
      return rejectWithValue(err);
    }
  },
);

export const saveQuickLoan = createAsyncThunk(
  'loan-application/save-quickloan',
  async (data, { rejectWithValue }) => {
    try {
      const res = await loanApi.saveQuickLoan(data);
      return res;
    } catch (err) {
      if (!err) {
        throw err;
      }
      return rejectWithValue(err);
    }
  },
);

export const getLoanCampaigns = createAsyncThunk(
  'loan-application/get-loan-campaigns',
  async (data = {}, { rejectWithValue }) => {
    try {
      const res = await loanApi.getLoanCampaigns();
      return res;
    } catch (err) {
      if (!err) {
        throw err;
      }
      return rejectWithValue(err);
    }
  },
);

export const saveLoanCampaign = createAsyncThunk(
  'loan-application/save-loan-campaign',
  async (data, { rejectWithValue }) => {
    try {
      const res = await loanApi.saveLoanCampaign(data);
      return res;
    } catch (err) {
      if (!err) {
        throw err;
      }
      return rejectWithValue(err);
    }
  },
);

export const checkLatest = createAsyncThunk(
  'loan-application/check-latest',
  async (_, { rejectWithValue }) => {
    try {
      const res = await loanApi.latest();
      return res;
    } catch (err) {
      if (!err) {
        throw err;
      }
      return rejectWithValue(err);
    }
  },
);

export const loanPreCheck = createAsyncThunk(
  'loan-application/loanPreCheck',
  async (data, { rejectWithValue }) => {
    try {
      const res = await loanApi.loanPreCheck(data);
      return res;
    } catch (err) {
      if (!err) {
        throw err;
      }
      return rejectWithValue(err);
    }
  },
);

export const cancelCurrentLoanLatest = createAsyncThunk(
  'loan-application/cancelCurrentLoanLatest',
  async (id, { rejectWithValue }) => {
    try {
      const res = await loanApi.cancelCurrentLoanLatest(id);
      return res;
    } catch (err) {
      if (!err) {
        throw err;
      }
      return rejectWithValue(err);
    }
  },
);

export const getLoanCampaign = createAsyncThunk(
  'loan-application/get-loan-campaign',
  async (data, { rejectWithValue }) => {
    try {
      const res = await loanApi.getLoanCampaign(data);
      return res;
    } catch (err) {
      if (!err) {
        throw err;
      }
      return rejectWithValue(err);
    }
  },
);

const loanSlice = createSlice({
  name: 'loan',
  initialState,
  reducers: {
    resetLoanState: () => initialState,
    setloanInfo(state, { payload }) {
      state.loanInfo = payload;
    },
    setQuickLoanFormData(state, { payload }) {
      state.quickLoanFormData = payload;
    },
    setMessage(state, { payload }) {
      state.message = payload;
    },
    setTempDataForEKYC(state, { payload }) {
      state.tempDataForEKYC = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getLoanApplicationList.fulfilled,
      (state, { payload }) => {},
    );
    builder.addCase(getLoanApplicationList.rejected, (state, { payload }) => {
      if (payload && payload.messageCode) {
        state.message = payload.messageCode;
      }
    });
    builder.addCase(saveQuickLoan.fulfilled, (state, { payload }) => {});
    builder.addCase(saveQuickLoan.rejected, (state, { payload }) => {
      state.message = payload.messageCode;
    });
    builder.addCase(getLoanCampaigns.fulfilled, (state, { payload }) => {});
    builder.addCase(getLoanCampaigns.rejected, (state, { payload }) => {
      state.message = payload.messageCode;
    });
    builder.addCase(saveLoanCampaign.fulfilled, (state, { payload }) => {});
    builder.addCase(saveLoanCampaign.rejected, (state, { payload }) => {
      state.message = payload.messageCode;
    });
    builder.addCase(checkLatest.fulfilled, (state, { payload }) => {});
    builder.addCase(checkLatest.rejected, (state, { payload }) => {
      state.message = payload.messageCode;
    });
    builder.addCase(cancelCurrentLoanLatest.rejected, (state, { payload }) => {
      state.message = payload.messageCode;
    });
    builder.addCase(loanPreCheck.rejected, (state, { payload }) => {
      state.message = payload.messageCode;
    });
    builder.addCase(getLoanCampaign.fulfilled, (state, { payload }) => {});
    builder.addCase(getLoanCampaign.rejected, (state, { payload }) => {
      state.message = payload.messageCode;
    });
  },
});

export const {
  setloanInfo,
  setQuickLoanFormData,
  setMessage,
  setTempDataForEKYC,
  resetLoanState,
} = loanSlice.actions;

export default loanSlice.reducer;
