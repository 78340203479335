const routes = {
  CHANGE_PASSWORD: '/change-password',
  CREATE_ACCOUNT: '/register',
  CREATE_ACCOUNT_SMS: '/register/:accessCode',
  EKYC_START: '/ekyc/start',
  EKYC_STEP1: '/ekyc/step1',
  EKYC_STEP2: '/ekyc/step2',
  EKYC_STEP3: '/ekyc/step3',
  ESIGN_PDF: '/e-sign/pdf',
  FAQ: '/faq',
  FAQ_QUESTION: '/faq/question',
  FORGOT_PASSWORD: '/forgot-password',
  FORGOT_PASSWORD_VERIFY_OTP: '/forgot-password/verify',
  HOME: '/',
  QUALIFY_FAILURE: '/qualify/failure',
  QUALIFY_SUCCESS: '/qualify/success',
  LOGIN: '/login',
  OTP: '/otp',
  QUICK_LOAN: '/quick-loan',
  QUICK_LOAN_PLAN: '/quick-loan/plan',
  USER_PROFILE: '/user-profile',
  VERIFY_OTP: '/register/verify',
};

export default routes;
