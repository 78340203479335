import BackButton from './PNG/backButton.png';
import BackGroundLeftContainer from './SVG/loginLanding.svg';
import ButtonDelete from './SVG/ButtonDelete.svg';
import Camera from './SVG/camera.svg';
import CameraFlip from './SVG/cameraFlip.svg';
import ConfirmInfoStep from './SVG/confirmInfoStep.svg';
import EmptyLoan from './PNG/EmptyLoan.png';
import ErrorIcon from './SVG/ErrorIcon.svg';
import FlashCam from './SVG/flashCam.svg';
import HelpIcon from './SVG/faq.svg';
import IconDelete from './SVG/IconDelete.svg';
import InfoStep from './SVG/infoStep.svg';
import Landing from './SVG/landing.svg';
import LandingLogo from './PNG/landing3x.png';
import LandingLogoVI from './PNG/landing-vi.png';
import LoginCover from './PNG/cover-login.png';
import LogoFB from './PNG/logoFacebook.png';
import LogoFrame from './PNG/logoFrame.png';
import LogoNice from './PNG/logoNice.png';
import LogoNiceFull from './PNG/logoNiceFull.png';
import LogoZalo from './PNG/logoZalo.png';
import Logout from './SVG/Logout.svg';
import Money from './PNG/money.png';
import ShinHanLogo from './PNG/logoShihan.png';
import ShinhanLogoFull from './PNG/shinhanbank.png';
import SuccessIcon from './SVG/success.svg';
import UploadStep from './SVG/uploadStep.svg';
import User from './SVG/user.svg';
import VpBank from './PNG/vpbank.png';
import WarningIcon from './PNG/warning.png';

export const PNG = {
  BackButton,
  BackGroundLeftContainer,
  EmptyLoan,
  LandingLogo,
  LandingLogoVI,
  LogoFB,
  LogoFrame,
  LogoNice,
  LogoNiceFull,
  LogoZalo,
  Money,
  ShinHanLogo,
  ShinhanLogoFull,
  VpBank,
  WarningIcon,
  LoginCover,
};

export const SVG = {
  ButtonDelete,
  Camera,
  CameraFlip,
  ConfirmInfoStep,
  ErrorIcon,
  FlashCam,
  HelpIcon,
  IconDelete,
  InfoStep,
  Landing,
  Logout,
  SuccessIcon,
  UploadStep,
  User,
};
