import axiosClient from './axiosClient';

export const userApi = {
  createAccount: async (data) => {
    const endPoint = '/user/register';
    return await axiosClient.post(endPoint, data);
  },
  getOTP: async (data) => {
    const endPoint = '/otp/send';
    return await axiosClient.post(endPoint, data);
  },
  verifyOTP: async (data) => {
    const endPoint = '/otp/verify';
    return await axiosClient.post(endPoint, data);
  },
  changePassword: async (data) => {
    const endPoint = '/user/change-password';
    return await axiosClient.post(endPoint, data);
  },
  isExisted: async (username) => {
    const endPoint = '/user/is-existed';
    return await axiosClient.get(endPoint, {
      params: { username },
    });
  },
  getUserProfile: async () => {
    const endPoint = '/user/info';
    return await axiosClient.get(endPoint);
  },
  userInfoSubmit: (data) => {
    const endPoint = '/user/info';
    return axiosClient.post(endPoint, data);
  },
  uploadAvatar: (data) => {
    const endPoint = '/user/avatar';
    return axiosClient.post(endPoint, data);
  },
  preCheckPhone: async (data) => {
    const endPoint = '/user/pre-check-phone';
    return await axiosClient.post(endPoint, data);
  },
  preCheckNid: async (data) => {
    const endPoint = '/user/pre-check-nid';
    return await axiosClient.post(endPoint, data);
  },
};
