import { Redirect, Route } from 'react-router-dom';

import DashBoard from '../../container/layouts/dashboard';
import React from 'react';
import routeConstant from '../../constants/routes';
import { store } from '../../redux/store';
import { useDispatch } from 'react-redux';
import { useWindowSide } from '../../hooks/useWindowSide';
import { logout } from '../../redux/authSlice';

const PrivateRoute = (props) => {
  const dispatch = useDispatch();
  const isPc = useWindowSide(992);
  const { isDashboard } = props;
  const { loginInfo } = store.getState().auth;

  const isUserLogged =
    loginInfo &&
    loginInfo.isLogin &&
    loginInfo.expires_at &&
    loginInfo.expires_at < Date.now();

  if (isUserLogged) {
    dispatch(logout());
  }

  if (loginInfo && loginInfo.isLogin) {
    return isPc && isDashboard ? (
      <DashBoard>
        <Route {...props} />
      </DashBoard>
    ) : (
      <Route {...props} />
    );
  }

  return <Redirect to={{ pathname: routeConstant.LOGIN }} />;
};

export default PrivateRoute;
