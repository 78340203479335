import axiosClient from './axiosClient';

export const qualifyApi = {
  qualify: async (data) => {
    const endPoint = '/qualification/qualify';
    return await axiosClient.post(endPoint, data);
  },
  saveInfo: async (data) => {
    const endPoint = '/qualification/save-info';
    return await axiosClient.post(endPoint, data);
  },
};
