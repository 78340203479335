import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { faqApi } from '../services/faqApi';

export const initialState = {
  message: null,
};

export const sendQuestion = createAsyncThunk(
  'faq/sendQuestion',
  async (data, { rejectWithValue }) => {
    try {
      const res = await faqApi.sendQuestion(data);
      return res;
    } catch (err) {
      if (!err) {
        throw err;
      }
      return rejectWithValue(err);
    }
  },
);

const faqSlice = createSlice({
  name: 'faq',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(sendQuestion.fulfilled, (state, { payload }) => {
      state.message = null;
    });
    builder.addCase(sendQuestion.rejected, (state, { payload }) => {
      state.message = payload.messageCode;
    });
  },
});

export const { setInfoCreateAccount, setMessage, setUserInfo } =
  faqSlice.actions;

export default faqSlice.reducer;
