import styled from 'styled-components';

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ModalImg = styled.img``;
export const ModalTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
  color: var(--color-neutral-n900);
`;
export const ModalMessage = styled.div`
  font-size: 14px;
  color: var(--color-neutral-n700);
  text-align: center;
  line-height: 130%;
  .link-to-new-pass {
    color: var(--color-primary-link);
  }
`;

export const ButtonModal = styled.div`
  border-radius: 4px;
  background: #186bbb;
  padding: 8px 16px;
  font-weight: bold;
  color: #fff;
  margin-top: 20px;
  min-width: 150px;
  text-align: center;
  cursor: pointer;
`;
