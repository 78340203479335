import { persistReducer, persistStore } from 'redux-persist';

import { RESET_APP } from './actionTypes';
// initialState
import { initialState as auth } from './authSlice';
import authReducer from './authSlice';
import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { initialState as ekyc } from './ekycSlice';
import ekycReducer from './ekycSlice';
import { initialState as esign } from './esignSlice';
import esignReducer from './esignSlice';
import { initialState as faq } from './faqSlice';
import faqReducer from './faqSlice';
import { initialState as loan } from './loanSlice';
import loanReducer from './loanSlice';
import logger from 'redux-logger';
import { initialState as qualify } from './qualifySlice';
import qualifySlice from './qualifySlice';
import storage from 'redux-persist/lib/storage';
import { initialState as user } from './userSlice';
import userReducer from './userSlice';

const persistConfig = {
  key: 'NICE',
  storage,
};

const initialStateRoot = {
  auth,
  ekyc,
  esign,
  faq,
  loan,
  qualify,
  user,
};

const appReducer = combineReducers({
  auth: authReducer,
  ekyc: ekycReducer,
  esign: esignReducer,
  faq: faqReducer,
  loan: loanReducer,
  qualify: qualifySlice,
  user: userReducer,
});

const rootReducer = (state, action) => {
  if (action.type === RESET_APP) {
    let newState = {};
    if (state) {
      Object.keys(state).forEach((key) => {
        newState[key] = initialStateRoot[key];
      });
    }
    return appReducer(newState, action);
  }

  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middlewares = [];

if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger);
}

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(middlewares),
});

export const persistor = persistStore(store);
