import axiosClient from './axiosClient';

export const authApi = {
  login: async (data) => {
    const endPoint = '/auth/login';
    return await axiosClient.post(endPoint, data);
  },
  logout: async () => {
    const endPoint = '/auth/logout';
    return await axiosClient.post(endPoint);
  },
};
