import 'react-toastify/dist/ReactToastify.css';
import './i18n';
import './styles/scss/main.scss';

import {
  ButtonModal,
  ModalImg,
  ModalMessage,
  ModalTitle,
  ModalWrapper,
} from './components/NiceModal/style';
import { persistor, store } from './redux/store';

import App from './container/app/App';
import { HTTP_STATUS } from './constants/api.constant';
import { Modal } from 'antd';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom';
import { SVG } from './asset/img';
import { ToastContainer } from 'react-toastify';
import axiosClient from './services/axiosClient';
import i18n from './i18n';
import { setLoading } from './redux/authSlice';

axiosClient.interceptors.request.use(async (config) => {
  const { loginInfo } = store.getState().auth;
  store.dispatch(setLoading(true));
  if (loginInfo && loginInfo.access_token) {
    config.headers.Authorization = `Bearer ${loginInfo.access_token}`;
  }
  return config;
});

axiosClient.interceptors.response.use(
  function (response) {
    store.dispatch(setLoading(false));
    return Promise.resolve(response);
  },
  function (error) {
    store.dispatch(setLoading(false));
    if (error.code === 'ECONNABORTED') {
      Modal.confirm({
        title: '',
        icon: null,
        width: '274px',
        centered: true,
        wrapClassName: 'none-footer',
        content: (
          <>
            <ModalWrapper>
              <ModalImg src={SVG.ErrorIcon} alt='' />
              <ModalTitle>{i18n.t('login.sorryTryAgain')}</ModalTitle>
              <ModalMessage>{i18n.t('requestTimeout')}</ModalMessage>
              <ButtonModal onClick={() => Modal.destroyAll()}>OK</ButtonModal>
            </ModalWrapper>
          </>
        ),
      });
      return Promise.reject('ECONNABORTED');
    }
    if (error && error.response) {
      const { status, data } = error.response;
      switch (status) {
        case HTTP_STATUS.BAD_REQUEST:
          // handle another action if user want
          return Promise.reject(data);
        case HTTP_STATUS.UNAUTHORIZED:
          // same
          return Promise.reject(data);
        case HTTP_STATUS.SERVER_ERROR:
          // same
          return Promise.reject(data);
        default:
          return Promise.reject(data);
      }
    }
  },
);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
    <ToastContainer autoClose={5000} />
  </React.StrictMode>,
  document.getElementById('root'),
);
